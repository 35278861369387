<app-header></app-header>

<div class="News-setions">
    <div class="container">
      <div class="row pt-5 pb-5">
        <div class="col-12 position-relative p-0">
          <div class="news-banner"></div>
          <div class="banner-heading">
            <h1><span style="color: #ffffff">News</span></h1>
          </div>
        </div>
        <div class="col-12 p-0 mt-5">
          <h2 class="entry-title">
            <a
              href="https://sprucepower.com/xl-fleet-to-rename-as-spruce-power-and-change-nyse-ticker-symbol-to-spru/"
            >
              XL Fleet to Rename as Spruce Power and Change NYSE Ticker Symbol
              to SPRU
            </a>
          </h2>
          <div class="inner-paragraph">
            <p>
              <strong>Houston, TX</strong>&nbsp;– Nov 04, 2022 – XL Fleet
              Corp. (NYSE: XL) (“XL Fleet” or the “Company”), a provider of
              subscription-based services that make it easy for homeowners and
              small businesses to own and maintain rooftop solar and battery
              storage, today announced that it will change its corporate name
              to Spruce Power Holding Corporation, effective November 14,
              2022. The Company will be known as Spruce Power. Additionally,
              the Company will change its NYSE ticker symbol from “XL” to
              “SPRU” at the open of market trading on Monday, November 14,
              2022.
            </p>
            <p>
              “We are excited to execute on the next stage of our new
              corporate strategy with the official change of our company
              name,” said Eric Tech, current Chief Executive Officer of XL
              Fleet. “Renaming our company to Spruce Power reflects our new
              focus on the residential solar and energy services market. Most
              importantly, it positions us to build upon the strong brand
              recognition that Spruce has earned in the residential solar
              market since 2018.”
            </p>
            <p>
              “Transitioning the Spruce Power name to our new public platform
              is an exciting achievement for our company,” added Christian
              Fong, President of Spruce Power. “Our new platform affords us
              the resources needed to elevate our company’s mission to a next
              level, and this change is only the beginning.” It is expected
              that Mr. Fong will be named CEO of the combined companies in the
              first quarter of 2023.
            </p>
            <p>
              <a
                class="post-item-link"
                href="https://www.businesswire.com/news/home/20221104005142/en/XL-Fleet-to-Rename-as-Spruce-Power-and-Change-NYSE-Ticker-Symbol-to-SPRU"
                target="_blank"
                rel="noopener"
                >Read More</a
              >
            </p>
            <span class="et_bloom_bottom_trigger"></span>
          </div>
        </div>
        <div class="col-12 p-0 mt-3 mt-sm-5 mt-md-5 mt-lg-5">
          <h2 class="entry-title">
            <a
              href="https://sprucepower.com/spruce-power-acquires-level-solars-16-5-mw-dc-rooftop-solar-portfolio/"
            >
              Spruce Power Acquires Level Solar’s 16.5 MW-dc Rooftop Solar
              Portfolio
            </a>
          </h2>

            <div class="inner-paragraph">
              <p>
                <strong>Houston, TX</strong>&nbsp;– July 26, 2022 – Spruce
                Power, the largest private owner and operator of distributed
                generation solar residential assets in the U.S., has closed on
                the acquisition of Level Solar’s entire portfolio of
                residential solar assets which has been managed by Long Island
                based, SUNation Energy since 2017. The deal closed in early
                July 2022 and extends Spruce’s stated strategy of providing a
                long-term home for not just the solar power assets, but the
                customers who, in choosing solar power, had made a positive
                clean energy choice for themselves and their communities.
              </p>
              <p>
                The 2,637 rooftop solar assets, totaling 16.5 MW-dc of power
                capacity, acquired by Spruce Power in this transaction
                increases the company’s portfolio by nearly five percent. This
                deal marks Spruce Power’s eleventh portfolio acquisition in
                the last three years and pushes its portfolio’s total to more
                than 335 MW of capacity.
              </p>
              <p>
                “Spruce Power has always been on the forefront of the M&amp;A
                market in residential solar. Well thought-out and
                strategically executed acquisitions have positioned us as the
                largest private residential solar company in the U.S.” notes
                Christian Fong, CEO of Spruce Power. “Hats off to the New York
                Green Bank, who originally worked with Level Solar in creating
                this portfolio, in helping make this transaction a success.
                The renewable energy industry has been seeing substantial
                growth and we are thrilled to continue to grow with it. ”
              </p>
              <p>
                <a
                  class="post-item-link"
                  href="https://www.prweb.com/releases/2022/07/prweb18809396.htm"
                  target="_blank"
                  rel="noopener"
                  >Read More</a
                >
              </p>
              <span class="et_bloom_bottom_trigger"></span>
            </div>
        </div>
        <div class="col-12 p-0 mt-3 mt-sm-5 mt-md-5 mt-lg-5">
          <h2 class="entry-title">
            <a
              href="https://sprucepower.com/spruce-acquires-two-residential-solar-portfolios-increases-holdings-to-more-than-330-mw/"
            >
              Spruce Acquires Two Residential Solar Portfolios, Increases
              Holdings to More than 330 MW
            </a>
          </h2>
            <div class="inner-paragraph">
              <p>
                <strong>HOUSTON, TX</strong>&nbsp;— November 17, 2020 —Spruce
                Finance, the largest private owner and operator of distributed
                generation residential solar assets in the U.S., has acquired
                two new asset portfolios, including the balance of NRG’s
                residential holdings. The NRG purchase is Spruce’s largest to
                date and the latest example of the company’s residential solar
                asset “growth by acquisition” strategy in the U.S.
              </p>
              <p>
                The NRG portfolio comprises 8,984 systems, totaling 60 MW.
                Spruce also acquired a portfolio from WEC Energy Group, made
                up of 1,047 systems, which Spruce had already been managing
                via its Energy Service Experts (ESE) subsidiary. Together, the
                acquisitions push Spruce’s total rooftop solar assets to 337
                MW, representing year-to-date portfolio growth of 78 percent
                and over 100 percent portfolio growth in the last 12 months.
              </p>
              <p>
                The company also executed a new $150 million senior credit
                facility that it will partially draw on to finance both deals.
              </p>
              <p>
                “We continue to demonstrate momentum and pursue our M&amp;A
                strategy, proving the value created by consolidating assets in
                the residential solar space,” said Christian Fong, CEO of
                Spruce Finance. “The trust and collaboration with our
                established partners like NRG and WEC Energy Group enables us
                to successfully execute on our strategy.”
              </p>
              <p>
                Spruce pivoted from loan origination to asset ownership and
                created ESE for asset management and maintenance in 2017 after
                restructuring and receiving an investment from HPS Investment
                Partners.
              </p>
              <p>
                The latest acquisitions follow deals from earlier this year
                that added nearly 112 MW to Spruce’s portfolio of residential
                solar. Spruce is actively pursuing other acquisition and
                strategic opportunities in…&nbsp;
              </p>
              <p>
                <a
                class="post-item-link"
                href="https://www.prnewswire.com/news-releases/spruce-acquires-two-residential-solar-portfolios-increases-holdings-to-more-than-330-mw-301174477.html"
                target="_blank"
                rel="noopener"
                >Read More</a
              >
              </p>
              <span class="et_bloom_bottom_trigger"></span>
            </div>
        </div>
        <div class="col-12 p-0 mt-3 mt-sm-5 mt-md-5 mt-lg-5">
            <h2 class="entry-title">
              <a
                href="https://sprucepower.com/spruce-finance-acquires-clearway-energys-portfolio-of-residential-solar-assets/"
              >
                Spruce Finance Acquires Clearway Energy’s Portfolio of
                Residential Solar Assets
              </a>
            </h2>
              <div class="inner-paragraph">
                <p>
                  <strong>HOUSTON, TX — May 14, 2020</strong> — Spruce
                  Finance, the largest private owner and operator of
                  distributed generation solar residential assets in the U.S.,
                  has bought Clearway Energy’s entire portfolio of residential
                  solar assets. The deal is Spruce’s largest to date and the
                  latest example of the company’s residential solar asset
                  “growth by acquisition” strategy in the U.S.
                </p>
                <p>
                  The 53 megawatts (MW) of rooftop solar assets acquired from
                  Clearway expands Spruce’s portfolio by 20 percent to more
                  than 250 MW. This brings Spruce’s year-to-date portfolio
                  growth to more than 30 percent, which already matches the 30
                  percent increase that the company achieved during 2019.
                </p>
                <p>
                  The deal also benefits Spruce’s independent subsidiary,
                  Energy Service Experts (ESE), which has entered into a
                  transitional asset management services partnership with NRG.
                  As a result, ESE has
                </p>
                <p>
                  “Despite the current global crisis, we expect strong and
                  stable returns—both financial and environmental—from
                  investing in a renewable future,” said Christian Fong, CEO
                  of Spruce Finance. “The Clearway acquisition comes because
                  of our strong relationships with others in the industry, and
                  underscores our ability to stay the course in a challenging
                  time. As a preferred sector in the broader energy and power
                  industry, we are enjoying continued access to the capital
                  markets and keep growing in our residential and distributed
                  generation solar markets.”
                </p>
                <p>
                  News of the Clearway deal comes on the back of Spruce’s
                  recent announcement that it closed a $124 million debt
                  financing deal for its portfolio of residential solar power
                  purchase agreements (PPAs) and leases with Vantage
                  Infrastructure and Sequoia Economic Infrastructure Income
                  Fund (SEQI). A portion of the capital infusion from those
                  funds has been used to acquire the Clearway assets as part
                  of Spruce’s strategic plan for expanding its holdings
                  through organic growth and M&amp;A activities.
                </p>
                <p>
                  About Spruce Finance<br />
                  Spruce Finance (www.sprucefinance.com) owns and operates a
                  growing portfolio of distributed generation solar
                  residential assets in the U.S. Headquartered in Houston with
                  executive offices in
                </p>
                <p cla>
                  <a
                    class="post-item-link"
                    href="https://www.prnewswire.com/news-releases/spruce-finance-acquires-clearway-energys-portfolio-of-residential-solar-assets-301059746.html"
                    target="_blank"
                    rel="noopener"
                    >Read More</a
                  >
                </p>
                <span class="et_bloom_bottom_trigger"></span>
              </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>